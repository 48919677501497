<template>
    <div class="app-wrapper">
        <Header></Header>
        <app-main></app-main>
        <Footer></Footer>
    </div>
</template>

<script>
import Header from './components/Header.vue'
import Footer from './components/Footer.vue'
import AppMain from './components/AppMain.vue'

export default {
    name: 'Layout',
    components: {
        Header,
        Footer,
        AppMain
    },
    computed: {

    },
    methods: {

    }
}
</script>

<style lang="less" scoped>
.app-wrapper {
    position: relative;
    height: 100%;
    width: 100%;
    overflow: hidden;
}
</style>
