import Vue from 'vue'
import App from './App.vue'
import router from './router'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import pdf from 'vue-pdf'
import axios from 'axios';
import _ from 'lodash'
import VueBus from 'vue-bus';
import '@/assets/css/index.css'
Vue.use(VueBus)
Vue.use(pdf)
Vue.config.productionTip = false
Vue.prototype.$http = axios;
Vue.prototype._ = _

// 接入前端监控数据 -start
import SlsReporter from 'sls-wpk-reporter'
import {
  wpkglobalerrorPlugin, // JS异常监控
  wpkperformancePlugin, // 性能监控
  wpkflowPlugin, // 默认插件，上报站点流量数据
  wpkinterfacePlugin // api请求异常
} from 'sls-wpk-reporter'

function isTest() {
  process.env.NODE_ENV !== 'production'
}

const wpk = new SlsReporter({
  bid: isTest() ? 'sls-fe31dd135ebfd71a05' : 'sls-3e72f83c407b064186', // 此处为添加应用的应用Id，每个应用均不同
  project: 'xiangzhu-front-monitor', // 对应阿里云日志服务的Project，不用改
  endpoint: 'cn-beijing.log.aliyuncs.com', // 外网入口，不用改
  slsParams: {
    app_name: 'zhjf-official-website' // 应用名称
  },
  rel: '2.2.1', // 前端版本号，推荐设置，设置后，可在平台进行版本对比等操作
  uid: 'useridStr', // 浏览当前页面的用户唯一标识，推荐设置
  spa: true, // 是否SPA应用
  debug: !!isTest(),
  plugins: [
    [wpkperformancePlugin],
    [
      wpkglobalerrorPlugin,
      {
        jsErr: true, // 是否开启JS异常监控。
        resErr: true // 是否开启资源加载异常监控。
      }
    ],
    [
      wpkflowPlugin,
      {
        enable: true
      }
    ],
    [
      wpkinterfacePlugin,
      {
        enable: true, // 是否开启API请求监控。默认为true，表示开启。
        sampleRate: 10, // API请求数据的采样率，默认为1,全部采样为100。
        withBody: true, // 是否上报请求Body。可能涉及敏感信息，因此默认为false，表示不上报。
        withResp: true, // 是否上报API返回数据。可能涉及敏感信息，因此默认为false，表示不上报。
        errorFilter: (params) => {
          console.log(params)
          // API请求异常数据过滤的回调函数，参数包含请求地址、响应码及响应内容。如果返回false，则不上报API请求异常数据。
          //  params格式为
          const customParams = {
            url: '',
            status: '',
            response: '',
            body: '',
            reqHeaders: {},
            resHeaders: {},
            queryString: ''
          }
          // 返回结果为false或者Object对象。如果返回false，则此次API请求异常数据直接丢弃。
          return customParams
        }
      }
    ]
  ]
})

wpk.install() // 初始化
// 接入前端监控数据 -end

Vue.use(ElementUI)
new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
