<template>
    <section class="app-main">
        <transition name="fade-transform" mode="out-in">
            <router-view></router-view>
        </transition>
    </section>
</template>

<script>
export default {
    name: 'AppMain',
}
</script>

<style lang="less" scoped>
.app-main {
    height: calc(100vh - 215px);
    width: 100%;
    overflow-y: auto;
}
</style>
