<template>
  <div class="questions-page" style>
    <el-card class="box-card">
      <div slot="header" class="clearfix">
        <span>常见问题</span>
      </div>
      <div class="content">
        <img :src="noDataImg" alt="">
        <span>暂无相关内容</span>
      </div>
    </el-card>
  </div>
</template>
<script>
import noDataImg from '@/assets/image/no-data.png'
export default {
  data() {
    return {
      activeNames: ['1'],
      noDataImg,
    };
  },
  methods: {
    handleChange(val) {
      console.log(val);
    }
  }
}
</script>

<style lang="less" scoped>
.questions-page {
  height: 100%;
  background-color: #e5e5e5;
  padding-top: 24px;
}
.box-card {
  width: 65%;
  margin: 0 auto;
  height: 90%;
  display: flex;
  flex-direction: column;
  /deep/ .el-card__body {
    flex: 1;
  }
  .content {
    height: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    text-align: center;
    justify-content: center;
    font-size: 14px;
    color: #666;
    img{
      width: 120px;
      height: 120px;
    }
  }
}
</style>