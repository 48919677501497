import request from "./request";
// const BMS_URL = process.env.VUE_APP_BMSROOT
// const APP_URL = process.env.VUE_APP_APPROOT
const H5_URL = process.env.VUE_APP_H5ROOT
const XZ_URL = process.env.VUE_APP_XZROOT

console.log(process.env)

export const fetchMobileVerificationCode = async (data) => {
    let result = await request({
        url: `${XZ_URL}/api/auth/sendCappSMSCode`,
        method: 'post',
        data
    })
    return result.data
}


export const fetchSubmitComment = async (data) => {
    let result = await request({
        url: `${XZ_URL}/api/question/v1/advice/collectAdvice`,
        method: 'post',
        data
    })
    return result.data
}

export const fetchImgCode = async (data) => {
    let result = await request({
        url: `${H5_URL}/msapi/captcha/captcha-image`,
        method: 'post',
        params: data
    })
    return result.data
}


export const fetchCheckImgCode = async (data) => {
    let result = await request({
        url: `${H5_URL}/msapi/captcha/captcha-check`,
        method: 'post',
        data
    })
    return result.data
}
