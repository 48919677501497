<template>
  <div class="comments-page">
    <Header></Header>
    <div class="comments-container">
      <div class="main">
        <el-card class="list">
          <div slot="header" class="clearfix">
            <span style="color: #333;font-weight: 500;font-size: 20px;">意见反馈</span>
          </div>
          <div v-if="listComments.length">
            <div class="item" v-for="(item,index) in listComments" :key="index">
              <div style="line-height: 20px;" v-text="item.desc"></div>
              <div style="margin-top: 12px;">
                <span class="mobile" v-text="item.mobile"></span>
                <span v-text="item.timeDesc"></span>
              </div>
            </div>
          </div>

          <div v-else style="margin-top:120px;text-align:center;font-size: 14px;color: #666;">暂无相关内容</div>
        </el-card>
        <el-card class="comment">
          <div class="title">意见反馈</div>
          <el-input
            style="resize:none;"
            type="textarea"
            :rows="6"
            placeholder="请填写您的建议,至少为8个字"
            v-model="commentTxt"
          ></el-input>
          <el-button
            style="width: 100%;margin-top: 12px;"
            type="danger"
            size="small"
            @click="fnOpenDialog"
            :disabled="commentTxt.length < 8"
          >提交</el-button>
        </el-card>
      </div>
      <Footer />
    </div>

    <el-dialog
      class="comment-dialog"
      title="验证手机号码"
      :visible.sync="centerDialogVisible"
      width="380px"
      center
      :before-close="fnCloseDialog"
    >
      <el-form ref="form" :model="form" :rules="rules" label-width="100px">
        <el-form-item label="手机号码" prop="mobile">
          <el-input size="medium" maxlength="11" v-model="form.mobile"></el-input>
        </el-form-item>

        <el-form-item label="短信验证码" class="message-code" prop="code">
          <el-input size="medium" maxlength="6" v-model="form.code"></el-input>
          <span
            class="btn-code"
            :style="{ color: countNum == 60 ? '#E8380D' : '#cccccc' }"
            v-text="btnDesc"
            @click="getMobileCode"
          ></span>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button style="width: 100%;" type="danger" @click="fnSubmitCheck">验证手机号码并提交</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>

import { fetchMobileVerificationCode, fetchSubmitComment, fetchImgCode } from '@/api'
import PicVerCodeVue from '@/components/picVerCode.vue'
import Header from '@/layout/components/Header.vue'
import Footer from '@/layout/components/Footer.vue'
export default {
  data() {
    var validatorPhone = function (rule, value, callback) {
      if (!value) {
        callback(new Error('手机号不能为空'))
      } else if (!/^1\d{10}$/.test(value)) {
        callback(new Error('手机号格式错误'))
      } else {
        callback()
      }
    }
    var validatorCode = function (rule, value, callback) {
      if (value === '') {
        callback(new Error('验证码不能为空'))
      } else if (value.length != 6) {
        callback(new Error('验证码长度有误'))
      } else {
        callback()
      }
    }
    return {
      form: {
        mobile: '',
        code: '',
        picCode: ''
      },
      rules: {
        mobile: [
          { required: true, validator: validatorPhone, trigger: 'blur' },
        ],
        code: [
          { required: true, validator: validatorCode, trigger: 'blur' },
        ],
      },
      btnDesc: '获取验证码',
      listComments: [{
        desc: '中和农信良心企业，现在外面的高利贷真是太可怕了，但是中和农信绝对是我所知道的良心企业，服务到家，贷款快，值得拥有。',
        timeDesc: '2022-01-04',
        mobile: '186****5482'
      },
      {
        desc: '服务好公司正规 ，下款很快，服务很好，利率透明除正常利息外无任何费用。',
        timeDesc: '2021-12-24',
        mobile: '136****7544'
      }, {
        desc: '乡助这是个不错的APP，帮我解决了资金问题，希望能利息能低一点，就更棒了！',
        timeDesc: '2021-11-19',
        mobile: '188****1165'
      }, {
        desc: '真正惠民的金融企业，比其他公司服务热情的多，而且客户经理会帮忙解决遇到的销路问题，指导如何提高产量的方法，良心企业啊',
        timeDesc: '2021-11-03',
        mobile: "176****3698"
      },
      {
        desc: '真的很方便，整体用下来感觉不错，真的是很快，网上也了解了一下，公司背景雄厚，用着还比较踏实。',
        timeDesc: '2021-10-08',
        mobile: '158****5639'
      },

      {
        desc: '我希望乡助平台可以越做越好，改善农民的生活质量，让农产品可以卖给更多的朋友！',
        timeDesc: '2021-08-20',
        mobile: '177****0499'
      }
      ],
      commentTxt: '',
      centerDialogVisible: false,
      timer: null,
      countNum: 60,
      showErr: null,
      imageData: null

    };
  },

  created() {
    this.getMobileCode = this._.debounce(this.getMobileCode, 1000, false)
    this.fnSubmitComment = this._.debounce(this.fnSubmitComment, 2000, false)
  },


  methods: {

    fnTimeCount() {
      clearInterval(this.timer)
      this.timer = setInterval(() => {
        if (this.countNum == 0) {
          this.btnDesc = '获取验证码'
          this.countNum = 60;
          clearInterval(this.timer)
          return;
        }
        this.countNum--;
        this.btnDesc = `${this.countNum}s后重新获取`
      }, 1000)
    },
    fnCloseDialog() {
      this.$refs.form.resetFields();
      this.centerDialogVisible = false;
    },
    fnOpenDialog() {
      this.centerDialogVisible = true;
      //  this.$refs.picVerCode&&this.$refs.picVerCode.getVerCodePic();
    },
    getMobileCode() {
      this.$refs.form.validateField('mobile', (checkValue) => {
        if (checkValue) return;
        if (this.countNum != 60) return
        this.fnTimeCount()
        let param = {
          mobile: this.form.mobile,
          type: 'REGIST',
          verifySmsChannel: 'h5',
          checkImage: null
        }
        fetchMobileVerificationCode(param).then(res => {

          if (res.success) {

            this.$message({
              message: '短信已成功发送，请注意查收',
              type: 'success'
            });
          } else {
            this.$message({
              message: '短信发送失败，请重试',
              type: 'warning'
            });
          }
        }).catch(() => {
          console.error('接口调用失败')
        })
      })

    },
    fnSubmitCheck() {
      this.$refs.form.validate((value) => {
        if (!value) return;

        this.fnSubmitComment();


      })
    },
    fnSubmitComment() {
      const params = {
        adviceContent: this.commentTxt,
        messageCode: this.form.code,
        phoneNumber: this.form.mobile,
        supportFlag: "",
        type: "1",
        version: "乡助升级v01"
      }
      fetchSubmitComment(params).then((res) => {
        if (res.code == 200) {
          this.centerDialogVisible = false;
          this.commentTxt = ''
          this.$message({
            message: res.message,
            type: 'success'
          });
        } else {
          this.$message({
            message: res.message,
            type: 'warning'
          });
        }
      }).catch(() => {
        this.$message({
          message: '接口调用失败！',
          type: 'error'
        })
      })
    },
  },
  components: {
    PicVerCodeVue,
    Header,
    Footer
  }
}
</script>

<style lang="less" scoped>
.comments-page {
  background-color: #e5e5e5;

  .comments-container {
    width: 100vw;
    padding-top: 24px;
    height: calc(100vh - 70px);
    overflow-y: auto;
    display: flex;
    overflow-x: hidden;
    flex-direction: column;
    justify-content: space-between;
    .main {
      display: flex;
      height: 100%;
      width: 65%;
      margin: 0 auto;
      margin-bottom: 24px;
    }

    .list {
      flex: 1;
      text-align: justify;
      font-size: 14px;
      color: rgb(102, 102, 102);
      .title {
        font-size: 24px;
        color: rgb(51, 51, 51);
        line-height: 1;
        font-weight: 500;
      }
      .item {
        padding: 16px 0;
        border-bottom: 1px solid #eeeeee;

        .mobile {
          margin-right: 20px;
        }
      }
      .item:last-child {
        border-bottom: none;
      }
      .item:first-child {
        padding-top: 0;
      }
    }

    .comment {
      width: 242px;
      height: 260px;
      margin-left: 12px;
      /deep/ .el-textarea__inner {
        resize: none;
      }

      .title {
        font-size: 14px;
        color: rgb(51, 51, 51);
        font-weight: 500;
        margin-bottom: 16px;
      }
    }
  }

  .comment-dialog {
    /deep/ .el-form-item {
      margin-bottom: 18px;
    }
    /deep/ .el-dialog__body {
      padding: 10px 32px;
    }
    /deep/ .el-dialog__footer {
      padding: 10px 32px;
      padding-bottom: 32px;
    }

    .pic-code {
      display: flex;

      .img-code {
        width: 80px;
        height: 32px;
      }
    }

    .pic-code /deep/ .el-input__inner {
      flex: 1;
    }
    .message-code {
      position: relative;
      .btn-code {
        width: 50%;
        position: absolute;
        right: 0;
        padding-right: 10px;
        text-align: right;
        user-select: none;
      }
    }
    .message-code /deep/ .el-input__inner {
      padding-right: 50%;
    }
  }
}
</style>