// export const headerList = ["规则目录", "意见征集", "违规公示", "合作机构"];
export const headerList = ["规则目录", "意见征集", "违规公示"];

export const businessItems = [
  {
    desc: "商户管理规则",
    pdfLink:
      "https://cdfinance-www.oss-cn-beijing.aliyuncs.com/xiangzhu-web/Rules/xzptsjglgz.pdf",
  },
  {
    desc: "招商标准及入驻规范",
    pdfLink:
      "https://cdfinance-www.oss-cn-beijing.aliyuncs.com/xiangzhu-web/Rules/zsbzjrzgf.pdf",
  },
  {
    desc: "保证金及平台服务费管理规则",
    pdfLink:
      "https://cdfinance-www.oss-cn-beijing.aliyuncs.com/xiangzhu-web/Rules/bzjjpttgfwfglgz.pdf",
  },
  {
    desc: "店铺命名规则",
    pdfLink:
      "https://cdfinance-www.oss-cn-beijing.aliyuncs.com/xiangzhu-web/Rules/dianpumingmingguize.pdf",
  },
  {
    desc: "品牌资质要求",
    pdfLink:
      "https://cdfinance-www.oss-cn-beijing.aliyuncs.com/xiangzhu-web/Rules/pinpaizizhiyaoqiu.pdf",
  },
  {
    desc: "各类目行业及商品资质规则",
    pdfLink:
      "https://cdfinance-www.oss-cn-beijing.aliyuncs.com/xiangzhu-web/Rules/glmhyjspzzgz.pdf",
  },
  {
    desc: "商品信息发布规范",
    pdfLink:
      "https://cdfinance-www.oss-cn-beijing.aliyuncs.com/xiangzhu-web/Rules/spxxfbgz.pdf",
  },
  {
    desc: "七天无理由退款规则",
    pdfLink:
      "https://cdfinance-www.oss-cn-beijing.aliyuncs.com/xiangzhu-web/Rules/qitianwuliyoutuikuanxieyi.pdf",
  },
  // {
  //   desc: "商家货款结算说明",
  //   pdfLink:
  //     "https://cdfinance-www.oss-cn-beijing.aliyuncs.com/xiangzhu-web/Rules/sjdkjssm.pdf",
  // },
  {
    desc: "代运营规则",
    pdfLink:
      "https://cdfinance-www.oss-cn-beijing.aliyuncs.com/xiangzhu-web/Rules/daiyunyingguize.pdf",
  },
  {
    desc: "退店规则",
    pdfLink:
      "https://cdfinance-www.oss-cn-beijing.aliyuncs.com/xiangzhu-web/Rules/tuidianguize.pdf",
  },
];
export const loansPdfLink =
  "https://cdfinance-www.oss-cn-beijing.aliyuncs.com/xiangzhu-web/Partner/chongqingzhonghenongxin.pdf";
export const insurancePdfLink =
  "https://cdfinance-www.oss-cn-beijing.aliyuncs.com/xiangzhu-web/Partner/xiaojingxianghai.pdf";
export const userPdfLink =
  "https://cdfinance-www.oss-cn-beijing.aliyuncs.com/xiangzhu-web/Private/yhzcfwxy.pdf";
export const privacyPdfLink =
  "https://cdfinance-www.oss-cn-beijing.aliyuncs.com/xiangzhu-web/Private/private.pdf";
export const sdkPdfLink =
  "https://cdfinance-www.oss-cn-beijing.aliyuncs.com/xiangzhu-web/Private/sjyhxxddsfsdklb.pdf";

import andriodIcon from "@/assets/image/andriod_icon.png";
import iosIcon from "@/assets/image/ios_icon.png";

export const bannerInfoList = [
  {
    imgSrc: 'https://oms-2018.oss-cn-hangzhou.aliyuncs.com/template/capp_banner_a.png',
    title: "商城",
    desc: "依托全国400余家县域服务网络优势，为品牌企业提供销售平台服务，通过乡助平台网络布局，实现商品分销的模式。为已经通过经销商在农村市场布局的企业，提供农村经销链条的销售服务。",
  },
  {
    imgSrc: 'https://oms-2018.oss-cn-hangzhou.aliyuncs.com/template/capp_banner_c.png',
    title: "农业服务",
    desc: "乡村振兴路，乡信在服务。构建“天上有网，地里有人”的立体化服务体系，让好技术、好服务、好产品精准落户田间地头。",
  },
//   {
//     imgSrc: 'https://oms-2018.oss-cn-hangzhou.aliyuncs.com/template/capp_banner_b.png',
//     title: "小额贷款",
//     desc: "通过手机客户端和扎根一线的本土员工，为乡村百姓提供可负担、有效、安全的金融支持，实现客户足不出户即可享受普金融服务。开展小额信贷业务，拾遗补缺，完善农村普惠金融服务体系，助力普惠金融发展。",
//   },
//   {
//     imgSrc: 'https://oms-2018.oss-cn-hangzhou.aliyuncs.com/template/capp_banner_d.png',
//     title: "小额保险",
//     desc: "乡村振兴，有“鲸”无险，保障万家，价格低；致力于让每一位老百姓都能买到价格最低的保险，让每一位老百姓都能买到适合自己的保险，让每一位老百姓都能够享受保险带来的保障。",
//   },
];

export const appDownloadImg = {
  andriod: 'https://oms-2018.oss-cn-hangzhou.aliyuncs.com/template/capp_code_andriod.png',
  ios: 'https://oms-2018.oss-cn-hangzhou.aliyuncs.com/template/capp_code_ios.png',
  andriodIcon,
  iosIcon,
};
