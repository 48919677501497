<template>
    <div class="rules-page">
        <Header />
        <div class="container">
            <div class="main">
                <div class="header">
                    <span
                        v-for="(item,index) in headerList"
                        :key="index"
                        v-text="item"
                        :class="{ active: index == activeIndex }"
                        @click.stop="fnHeaderTabClick(index)"
                    ></span>
                </div>
                <div class="body" v-if="activeIndex == 0">
                    <div class="aside">
                        <el-menu
                            :default-active="currentRulesAsideIndex"
                            class="el-menu-vertical"
                            @select="fnRulesSelectAsideIndex"
                        >
                            <!-- <el-menu-item index="1">
                                <span slot="title">用户规则</span>
                            </el-menu-item> -->
                            <el-menu-item index="2">
                                <span slot="title">商家规则</span>
                            </el-menu-item>
                            <!-- <el-menu-item index="3">
                                <span slot="title">隐私政策</span>
                            </el-menu-item> -->
                        </el-menu>
                    </div>
                    <div class="content" v-show="currentRulesAsideIndex == 1">
                        <ul>
                            <li
                                class="item"
                                @click.stop="fnRulesHanderItem('user', '规则目录 >平台基础规则 >用户协议')"
                            >用户协议</li>
                        </ul>
                    </div>
                    <div class="content" v-show="currentRulesAsideIndex == 2">
                        <ul>
                            <li
                                class="item"
                                v-for="(item,index) in businessItems"
                                v-text="item.desc"
                                @click.stop="fnRulesHanderItem(item.pdfLink, item.desc)"
                            ></li>
                        </ul>
                    </div>
                    <div class="content" v-show="currentRulesAsideIndex == 3">
                        <ul>
                            <li
                                class="item"
                                @click.stop="fnRulesHanderItem('privacy', '规则目录 >隐私政策 >乡助隐私政策')"
                            >乡助隐私政策</li>
                            <li
                                class="item"
                                @click.stop="fnRulesHanderItem('sdk', '规则目录 >隐私政策 >收集用户信息的第三方SDK列表')"
                            >收集用户信息的第三方SDK列表</li>
                        </ul>
                    </div>
                    <div class="content" v-if="isShowPdfContent">
                        <span v-text="pdfTitle" style="font-size: 12px;color:#333"></span>
                        <PdfContainer ref="pdfCom"></PdfContainer>
                    </div>
                </div>

                <div class="body" v-if="activeIndex == 1 && !isShowComment">
                    <div class="aside">
                        <el-menu
                            :default-active="currentCommentAsideIndex"
                            class="el-menu-vertical"
                            @select="fnCommentSelectAsideIndex"
                        >
                            <el-menu-item index="1">
                                <span slot="title">意见征集中</span>
                            </el-menu-item>
                            <el-menu-item index="2">
                                <span slot="title">等待公示</span>
                            </el-menu-item>
                            <el-menu-item index="3">
                                <span slot="title">结果已公示</span>
                            </el-menu-item>
                        </el-menu>
                    </div>
                    <div class="content" v-show="currentCommentAsideIndex == 1">
                        <!-- <ul>
                            <li class="item" @click="fnShowComment">收集用户信息的第三方SDK协议</li>
                        </ul>-->
                        <div class="empty-desc">
                            <img :src="noDataImg" alt />
                            <span>暂无相关内容</span>
                        </div>
                    </div>
                    <div class="content" v-show="currentCommentAsideIndex == 2">
                        <div class="empty-desc">
                            <img :src="noDataImg" alt />
                            <span>暂无相关内容</span>
                        </div>
                    </div>
                    <div class="content" v-show="currentCommentAsideIndex == 3">
                        <div class="empty-desc">
                            <img :src="noDataImg" alt />
                            <span>暂无相关内容</span>
                        </div>
                    </div>
                </div>
                <div class="body" v-if="activeIndex == 2">
                    <div
                        class="content"
                        style="margin-left: 0;padding-right: 20px;padding-left: 20px;"
                    >
                        <p
                            style="font-size: 12px;color:#333;"
                        >根据《中华人民共和国电子商务法》，《网络交易监督管理办法》等规定，对发生违反法律、法规行为，被实施警示、暂停或者终止服务等处理措施的违规商家信息进行如下公示：</p>
                        <p
                            style="font-size: 12px;color:#333;text-align: center;margin-top: 24px;"
                        >暂无违规信息公示</p>
                    </div>
                </div>
                <div class="body" v-if="activeIndex == 3">
                    <div class="aside">
                        <el-menu
                            :default-active="currentAgencyAsideIndex"
                            class="el-menu-vertical"
                            @select="fnAgencySelectAsideIndex"
                        >
                            <el-menu-item index="1">
                                <span slot="title">小额贷款</span>
                            </el-menu-item>
                            <el-menu-item index="2">
                                <span slot="title">小额保险</span>
                            </el-menu-item>
                        </el-menu>
                    </div>
                    <div class="content" v-show="currentAgencyAsideIndex == 1">
                        <ul>
                            <li
                                class="item"
                                @click.stop="fnAgencyHanderItem('loans', '合作机构 >小额贷款 >重庆市中和农信小额贷款有限公司')"
                            >重庆市中和农信小额贷款有限公司</li>
                        </ul>
                    </div>
                    <div class="content" v-show="currentAgencyAsideIndex == 2">
                        <ul>
                            <li
                                class="item"
                                @click.stop="fnAgencyHanderItem('insurance', '合作结构 >小额保险 >北京小鲸向海保险代理有限公司')"
                            >北京小鲸向海保险代理有限公司</li>
                        </ul>
                    </div>
                    <div class="content" v-if="isShowAgencyPdfContent">
                        <span v-text="pdfTitle" style="font-size: 12px;color:#333"></span>
                        <PdfContainer ref="pdfComA"></PdfContainer>
                    </div>
                </div>
                <div class="comment-show" v-show="isShowComment">
                    <CallComment></CallComment>
                </div>
            </div>
            <Footer></Footer>
        </div>
    </div>
</template>

<script>
import PdfContainer from '@/views/PdfContainer.vue'
import Header from '@/layout/components/Header.vue'
import Footer from '@/layout/components/Footer.vue'
import noDataImg from '@/assets/image/no-data.png'

import CallComment from './CallComment.vue'
import { headerList, businessItems, loansPdfLink, insurancePdfLink, userPdfLink, privacyPdfLink,sdkPdfLink } from './common'
export default {
    data() {
        return {
            headerList,
            businessItems,
            activeIndex: 0,
            noDataImg,
            currentRulesAsideIndex: '2',
            currentCommentAsideIndex: '1',
            currentAgencyAsideIndex: '1',
            pdfTitle: '',
            isShowPdfContent: false,
            isShowAgencyPdfContent: false,
            loansPdfLink,
            insurancePdfLink,
            userPdfLink,
            privacyPdfLink,
            sdkPdfLink,
            isShowComment: false,
        };
    },
    methods: {

        fnHeaderTabClick(index) {
            this.activeIndex = index
            this.currentRulesAsideIndex = '2'
            this.currentCommentAsideIndex = '1'
            this.currentAgencyAsideIndex = '1'
            this.isShowAgencyPdfContent = false;
            this.isShowPdfContent = false;
            this.isShowComment = false;
        },
        fnRulesSelectAsideIndex(index) {
            this.isShowPdfContent = false;
            this.currentRulesAsideIndex = index;
        },
        fnCommentSelectAsideIndex(index) {
            this.currentCommentAsideIndex = index;
        },
        fnAgencySelectAsideIndex(index) {
            this.isShowAgencyPdfContent = false;
            this.currentAgencyAsideIndex = index;
        },
        fnRulesHanderItem(flag, desc) {
            this.activeIndex = 0;
            this.isShowPdfContent = true;
            this.currentRulesAsideIndex = null;
            let pdfLink = null
            if (flag == 'user' || flag == 'privacy' || flag == 'sdk') {
                this.pdfTitle = desc
                if (flag == 'user') {
                    pdfLink = this.userPdfLink;
                }
                if (flag == 'privacy') {
                    pdfLink = this.privacyPdfLink
                }
                if (flag == 'sdk') {
                    pdfLink = this.sdkPdfLink
                }
            } else {
                this.pdfTitle = `规则目录 >隐私政策 >${desc}`
                pdfLink = flag
            }

            this.$nextTick(() => {
                this.$refs.pdfCom.getNumPages(pdfLink)
            })
        },

        fnAgencyHanderItem(index, desc) {
            this.isShowAgencyPdfContent = true;
            this.currentAgencyAsideIndex = null
            let pdfLink = null
            this.pdfTitle = desc
            if (index == 'insurance') {
                pdfLink = this.insurancePdfLink
            }
            if (index == 'loans') {
                pdfLink = this.loansPdfLink
            }
            this.$nextTick(() => {
                this.$refs.pdfComA.getNumPages(pdfLink)

            })
        },
        fnShowComment() {
            this.isShowComment = true;
        }


    },
    components: {
        PdfContainer,
        Header,
        Footer,
        CallComment
    },
    mounted() {

        this.$bus.$on('setCustomType', (value) => {
            if (value == 'agency') {
                this.activeIndex = 3;
                return;
            }
            this.isShowPdfContent = false;
            setTimeout(() => {
                if (value == 'user') {
                    this.fnRulesHanderItem('user', '规则目录 >平台基础规则 >用户协议')
                }
                if (value == 'privacy') {
                    this.fnRulesHanderItem('privacy', '规则目录 >隐私政策 >乡助隐私政策')
                };
            }, 20);


        })
    },
    destroyed: function () {
        this.$bus.off("setCustomType");
    },
}
</script>

<style lang="less" scoped>
.rules-page {
    background-color: #e5e5e5;
    overflow: hidden;
}
.container {
    width: 100%;
    height: calc(100vh - 70px);
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    .main {
        flex: 1;
        padding-top: 24px;
        display: flex;
        flex-direction: column;
    }

    .header {
        width: 65%;
        margin: 0 auto;
        display: flex;
        height: 60px;
        align-items: center;
        background-color: #ffffff;
        border-radius: 4px;
        span {
            margin: 0 20px;
            font-size: 14px;
            color: rgb(51, 51, 51);
            cursor: pointer;
        }
        span.active {
            color: rgb(232, 56, 13);
        }
    }
    .body {
        flex: 1;
        width: 65%;
        margin: 0 auto;
        display: flex;
        justify-content: space-between;
        margin-top: 16px;
        margin-bottom: 24px;
        /deep/ .el-menu {
            border: none;
        }
        /deep/ .el-menu-item {
            height: 50px;
            line-height: 50px;
        }
        /deep/ .el-menu-item.is-active::before {
            content: "";
            position: absolute;
            width: 4px;
            height: 100%;
            background-color: #e8380d;
            margin-left: -20px;
        }
        .aside {
            height: 100%;
            .el-menu-vertical {
                width: 198px;
                border-radius: 4px;
            }
        }
        .content {
            flex: 1;
            height: 100%;
            background-color: #fff;
            margin-left: 12px;
            padding: 16px;
            padding-right: 0;
            border-radius: 4px;
            overflow: hidden;
            .empty-desc {
                display: flex;
                height: 100%;
                align-items: center;
                justify-content: center;
                flex-direction: column;
                font-size: 14px;
                color: #333333;
                img {
                    width: 120px;
                    height: 120px;
                }
            }
            ul {
                // height: 100%;
                height: calc(100vh - 380px);
                overflow-y: auto;
            }
            li.item {
                display: flex;
                align-items: center;
                color: #666;
                font-size: 14px;
                line-height: 20px;
                margin-bottom: 16px;
                height: 20px;
                cursor: pointer;
            }
            li.item:last-child {
                margin-bottom: 0;
            }
            li.item:hover {
                color: rgb(232, 56, 13);
                text-decoration: underline;
            }
            li.item::before {
                content: "";
                position: relative;
                height: 4px;
                width: 4px;
                background-color: #ccc;
                border-radius: 50%;
                display: inline-block;
                margin-right: 14px;
            }
        }
    }
    .comment-show {
        flex: 1;
        margin-top: 16px;
        margin-bottom: 24px;
    }
}
</style>