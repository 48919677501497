<template>
    <div class="header-component">
        <div class="left-content">
            <img class="logoA" :src="logoA" alt />
            <img class="logoB" :src="logoB" alt />
        </div>
        <div class="right-content">
            <div class="item" v-for="(item,index) in tabList" @click="goPage(item.route, index)">
                <span class="desc" :class="{ line: currentPath == item.route }" v-text="item.desc"></span>
            </div>
        </div>
    </div>
</template>

<script>
import logoA from '@/assets/image/logo_1.png'
import logoB from '@/assets/image/logo_2.png'
export default {
    data() {
        return {
            logoA,
            logoB,
            activeIndex: 0,
            tabList: [{
                desc: '首页',
                route: '/home'
            }, {
                desc: '常见问题',
                route: '/questions'
            }, {
                desc: '规则中心',
                route: '/rules'
            }]
        }
    },
    computed: {
        currentPath() {
            return this.$route.path
        }
    },
    methods: {
        goPage(path, index) {

            this.activeIndex = index;
            this.$router.push({
                path,
            })
        }
    }
}
</script>

<style lang="less" scoped>
.header-component {
    height: 70px;
    background-color: #fff;
    padding: 0 17.5%;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .left-content {
        height: 100%;
        display: flex;
        align-items: center;
        .logoA {
            width: 49.31px;
            height: 32.66px;
        }
        .logoB {
            width: 54px;
            height: 54px;
        }
    }
    .right-content {
        height: 100%;
        display: flex;
        .item {
            display: flex;
            flex-direction: column;
            margin-left: 60px;
            cursor: pointer;
        }
        .item:nth-of-type(1) {
            margin-left: 0;
        }
        .desc {
            font-size: 14px;
            color: #333;
            line-height: 70px;
            height: 70px;
            padding: 0 2px;
            &:hover {
                border-bottom: 4px solid #e8380d;
            }
        }
        .line {
            border-bottom: 4px solid #e8380d;
        }
    }
}
</style>