<template>
    <div @click="getVerCodePic()" class="pic_vercode">
        <!-- <span v-if="showErr">点击重试</span> -->
        <!-- <img v-else class="pic_vercode" :src="imageData" /> -->
        <img :src="imageData" alt />
    </div>
</template>

<script>

import { fetchImgCode } from '@/api'
export default {
    props: {
        // 操作类型 LOGIN,REGISTER,FORGETPWD,
        type: {
            type: String,
            default: 'REGISTER '
        }
    },
    data() {
        return {
            imageData: '',
            showErr: false, //是否显示失败提示
        }
    },
    mounted() {

        console.log('组件加载了')
        this.getVerCodePic();
    },

    beforeUnMounted() {
        window.localStorage.setItem('imgCodeId', null)
    },
    methods: {
        async getVerCodePic() {
            let imgCodeId = this.randomString();

            window.localStorage.setItem('imgCodeId', imgCodeId)

            let param = {
                'bizCode': 'FIN',
                'captchaImageType': 'FORGETPWD',
                'tokenid': imgCodeId
            }
            const res = await fetchImgCode(param)

            if (res.success) {
                let image = 'data:image/jpeg;base64,' + resp.data;

                // 返回的数据多了换行符号 需要去掉一下
                image = image.replace(/\r/ig, "");
                image = image.replace(/\n/ig, "");

                this.imageData = image;
                this.showErr = false;
            } else {
                this.showErr = true;
            }



        },
        randomString(len) {
            len = len || 32;
            var chars = 'ABCDEFGHJKMNPQRSTWXYZabcdefhijkmnprstwxyz2345678';    /****默认去掉了容易混淆的字符oOLl,9gq,Vv,Uu,I1****/
            var maxPos = chars.length;
            var pwd = '';
            for (let i = 0; i < len; i++) {
                pwd += chars.charAt(Math.floor(Math.random() * maxPos));
            }
            return pwd;
        }
    }
}
</script>

<style>
.pic_vercode {
    width: 60px;
    height: 30px;
    background-color: #efefef;
}
</style>
