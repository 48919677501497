<template>
  <div class="comments-page">
    <el-card class="list">
      <div class="header">收集用户信息的第三方SDK协议</div>
      <div class="date">发布日期：2021年12月21日</div>
      <div class="section">
        <div
          class="title"
        >请您注意，本政策不适用于您通过我们的产品/服务而接入的其他第三方产品/服务(“其他第三方”，包括您的交易相对方、任何第三方网站以及第三方服务提供者等)，具体规定请参照该第三方的隐私政策或类似声明。</div>
        <div class="title">一、我们如何收集和使用您的个人信息</div>
        <div class="desc">
          1、为了向您提供我们产品/服务的基本功能，您需要授权我们收集、使用必要信息的情形，如您拒绝提供前述必要信息，您将无法正常使用我们的的产品/服务；
          <br />2、为了向您提供我们产品/服务的拓展功能，您可以选择授权我们收集、使用信息的情形，如您拒绝提供前述信息，您将无法正常使用相关附加功能或无法实现我们拟达到的功能效果，但并不会影响您正常使用我们产品/服务的基本功能。
        </div>
      </div>
    </el-card>
    <el-card class="comment">
      <div class="title">投票中</div>
      <div class="desc">关于本次规则修改的内容，您的看法是？</div>
      <el-radio-group class="check-btn" v-model="checkValue" size="small">
        <el-radio :label="1">支持</el-radio>
        <el-radio :label="2">有其他意见</el-radio>
      </el-radio-group>
      <el-input
        style="resize:none;"
        type="textarea"
        :rows="6"
        v-if="checkValue == 2"
        placeholder="请填写您的建议,至少为8个字"
        v-model="commentTxt"
      ></el-input>
      <el-button
        style="width: 100%;margin-top: 12px;"
        type="danger"
        size="small"
        @click="fnOpenDialog"
        :disabled="commentTxt.length < 8 && checkValue == 2"
      >提交</el-button>
    </el-card>
    <el-dialog
      class="comment-dialog"
      title="验证手机号码"
      :visible.sync="centerDialogVisible"
      width="380px"
      center
      :before-close="fnCloseDialog"
    >
      <el-form ref="form" :model="form" :rules="rules" label-width="100px">
        <el-form-item label="手机号码" prop="mobile">
          <el-input size="medium" maxlength="11" v-model="form.mobile"></el-input>
        </el-form-item>

        <el-form-item label="短信验证码" class="message-code" prop="code">
          <el-input size="medium" maxlength="6" v-model="form.code"></el-input>
          <span
            class="btn-code"
            :style="{ color: countNum == 60 ? '#E8380D' : '#cccccc' }"
            v-text="btnDesc"
            @click="getMobileCode"
          ></span>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button style="width: 100%;" type="danger" @click="fnSubmitCheck">验证手机号码并提交</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>

import { fetchMobileVerificationCode, fetchSubmitComment, fetchImgCode } from '@/api'
import PicVerCodeVue from '@/components/picVerCode.vue'
export default {
  data() {
    var validatorPhone = function (rule, value, callback) {
      if (!value) {
        callback(new Error('手机号不能为空'))
      } else if (!/^1\d{10}$/.test(value)) {
        callback(new Error('手机号格式错误'))
      } else {
        callback()
      }
    }
    var validatorCode = function (rule, value, callback) {
      if (value === '') {
        callback(new Error('验证码不能为空'))
      } else if (value.length != 6) {
        callback(new Error('验证码长度有误'))
      } else {
        callback()
      }
    }
    return {
      form: {
        mobile: '',
        code: '',
        picCode: ''
      },
      rules: {
        mobile: [
          { required: true, validator: validatorPhone, trigger: 'blur' },
        ],
        code: [
          { required: true, validator: validatorCode, trigger: 'blur' },
        ],
      },
      btnDesc: '获取验证码',
      listComments: [
      ],
      commentTxt: '',
      centerDialogVisible: false,
      timer: null,
      countNum: 60,
      showErr: null,
      imageData: null,
      checkValue: 1,

    };
  },

  created() {
    this.getMobileCode = this._.debounce(this.getMobileCode, 1000, false)
    this.fnSubmitComment = this._.debounce(this.fnSubmitComment, 2000, false)
    // this.fnGetImgCode()
  },


  methods: {

    fnTimeCount() {
      clearInterval(this.timer)
      this.timer = setInterval(() => {
        if (this.countNum == 0) {
          this.btnDesc = '获取验证码'
          this.countNum = 60;
          clearInterval(this.timer)
          return;
        }
        this.countNum--;
        this.btnDesc = `${this.countNum}s后重新获取`
      }, 1000)
    },
    fnCloseDialog() {
      this.$refs.form.resetFields();
      this.centerDialogVisible = false;
    },
    fnOpenDialog() {
      this.centerDialogVisible = true;
      //  this.$refs.picVerCode&&this.$refs.picVerCode.getVerCodePic();
    },
    getMobileCode() {
      this.$refs.form.validateField('mobile', (checkValue) => {
        if (checkValue) return;
        if (this.countNum != 60) return
        this.fnTimeCount()
        let param = {
          mobile: this.form.mobile,
          type: 'REGIST',
          verifySmsChannel: 'h5',
          checkImage: null
        }
        fetchMobileVerificationCode(param).then(res => {

          if (res.success) {

            this.$message({
              message: '短信已成功发送，请注意查收',
              type: 'success'
            });
          } else {
            this.$message({
              message: '短信发送失败，请重试',
              type: 'warning'
            });
          }
        }).catch(() => {
          console.error('接口调用失败')
        })
      })

    },
    fnSubmitCheck() {
      this.$refs.form.validate((value) => {
        if (!value) return;

        this.fnSubmitComment();


      })
    },
    fnSubmitComment() {
      const params = {
        adviceContent: this.checkValue == 2 ? this.commentTxt : '',
        messageCode: this.form.code,
        phoneNumber: this.form.mobile,
        supportFlag: this.checkValue,
        type: "0",
        version: "乡助升级v01"
      }
      fetchSubmitComment(params).then((res) => {
        if (res.code == 200) {
          this.$refs.form.resetFields();
          this.centerDialogVisible = false;
          this.commentTxt = ''
          this.$message({
            message: res.message,
            type: 'success'
          });
        } else {
          this.$message({
            message: res.message,
            type: 'warning'
          });
        }
      }).catch(() => {
        this.$message({
          message: '接口调用失败！',
          type: 'error'
        })
      })
    },
  },
  components: {
    PicVerCodeVue
  },
}
</script>

<style lang="less" scoped>
.comments-page {
  background-color: #e5e5e5;
  height: 100%;
  display: flex;
  width: 65%;
  margin: 0 auto;
  .list {
    flex: 1;
    height: 100%;
    overflow-y: auto;
    text-align: justify;
    font-size: 14px;
    color: rgb(102, 102, 102);
    .header {
      font-size: 16px;
      font-weight: 500;
      color: rgb(51, 51, 51);
    }
    .date {
      font-size: 12px;
      color: #ccc;
      margin: 6px 0;
    }
    .title {
      font-size: 14px;
      color: rgb(51, 51, 51);
      line-height: 20px;
      font-weight: 500;
      margin: 6px 0;
    }
    .desc {
      line-height: 20px;
    }
  }

  /deep/ .is-always-shadow {
    box-shadow: 0 0 0 0;
  }
  .comment {
    width: 242px;
    height: auto;
    margin-left: 12px;
    color: #333333;
    background-color: transparent;
    border: none;

    /deep/ &.is-hover-shadow:focus,
    /deep/ &.is-hover-shadow:hover {
      box-shadow: none;
    }
    /deep/ .el-textarea__inner {
      margin-top: 4px;
      resize: none;
    }
    /deep/ .el-card__body {
      padding: 16px;
    }
    /deep/ .el-radio {
      margin: 6px 0;
    }
    /deep/ .el-card {
      background: transparent;
      opacity: 1;
    }
    /deep/ .el-card__body {
      background: #ffffff;
    }

    .title {
      width: 44px;
      height: 20px;
      font-size: 12px;
      line-height: 20px;
      color: #fff;
      margin-bottom: 12px;
      background-color: #e8380d;
      text-align: center;
    }
    .desc {
      font-size: 14px;
      margin-bottom: 12px;
    }
    .check-btn {
      display: flex;
      flex-direction: column;
    }
  }

  .comment-dialog {
    /deep/ .el-form-item {
      margin-bottom: 18px;
    }
    /deep/ .el-dialog__body {
      padding: 10px 32px;
    }
    /deep/ .el-dialog__footer {
      padding: 10px 32px;
      padding-bottom: 32px;
    }

    .pic-code {
      display: flex;

      .img-code {
        width: 80px;
        height: 32px;
      }
    }

    .pic-code /deep/ .el-input__inner {
      flex: 1;
    }
    .message-code {
      position: relative;
      .btn-code {
        width: 50%;
        position: absolute;
        right: 0;
        padding-right: 10px;
        text-align: right;
        user-select: none;
      }
    }
    .message-code /deep/ .el-input__inner {
      padding-right: 50%;
    }
  }
  /deep/ .el-radio-group {
    .el-radio__input.is-checked .el-radio__inner {
      border-color: #e8380d;
      background: #e8380d;
    }
    .el-radio__input .el-radio__inner {
      &:hover {
        border: 1px solid #e8380d;
      }
    }
    .el-radio__input.is-checked + .el-radio__label {
      color: #333333;
    }
  }
}
</style>