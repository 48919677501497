
<template>
    <div id="app">
        <router-view></router-view>
    </div>
</template>

<script>
export default {
    name: 'App',
    data() {
        return {}
    },
    onError(error) {
        console.log('error', error)
    // eslint-disable-next-line no-undef
        handleListenerError(error)
    },
}
</script>

<style lang="less" scoped>
#app {
    width: 100vw;
    height: 100vh;
    overflow: hidden;
}
</style>

