import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import CommonQuestions from '../views/CommonQuestions.vue'
import RulesCenter from '../views/RulesCenter.vue'
import Comments from '../views/Comments.vue'
import Layout from '@/layout/index.vue'

Vue.use(VueRouter)

const routes = [

  {
    path: '/',
    component: Layout,
    redirect: '/home',
    children: [{
      path: 'home',
      name: 'Home',
      component: Home,
    }, {
      path: 'questions',
      name: 'Questions',
      component: CommonQuestions,
    },
]

  },

  {
    path: '/rules',
    name: 'Rules',
    component: RulesCenter,
  },

  {
    path: '/comments',
    name: 'Comments',
    component: Comments,
  },
  {
    path: '*',
    redirect: '/home'
  }


]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
