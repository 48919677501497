<template>
  <div>
    <swiper class="swiper" :options="swiperOption">
      <swiper-slide class="section" v-for="(item, index) in bannerInfoList" :key="index">
        <div class="content">
          <div class="left-content">
            <img class="swiper-img" :src="item.imgSrc" alt />
          </div>
          <div class="right-content">
            <div class="title" v-text="item.title"></div>
            <div class="desc" v-text="item.desc"></div>
            <div class="btn-box">
              <div class="andriod-btn">
                安卓版下载
                <img class="andriod-icon" :src="appDownloadImg.andriodIcon" alt />

                <img class="code-img" :src="appDownloadImg.andriod" alt />
              </div>
              <div class="ios-btn">
                iOS版下载
                <img class="ios-icon" :src="appDownloadImg.iosIcon" alt />

                <img class="code-img" :src="appDownloadImg.ios" alt />
              </div>
            </div>
          </div>
        </div>
      </swiper-slide>
      <div class="swiper-pagination" slot="pagination"></div>
    </swiper>
    <div class="video-container" v-show="isShowVideo">
      <div id="mse">
        <el-button
          style="display: flex;align-items: center;justify-content: center;background-color: #000;color:#fff;font-weight: bold;"
          size="mini"
          class="close-icon"
          icon="el-icon-close"
          circle
          @click.stop="closeVideo"
        ></el-button>
      </div>
    </div>
    <img @click.stop="openVideo" class="video-icon" src="@/assets/image/videoIcon.png" alt />
    <!-- <img class="falv-tag" src="https://oms-2018.oss-cn-hangzhou.aliyuncs.com/template/20231130160530.jpg" alt /> -->
  </div>
</template>

<script>
import { Swiper, SwiperSlide } from 'vue-awesome-swiper'
import 'swiper/css/swiper.css'

import { bannerInfoList, appDownloadImg } from './common'

// import VideoDialogVue from './VideoDialog.vue'

import Player from 'xgplayer/dist/simple_player';
import volume from 'xgplayer/dist/controls/volume';


export default {
  name: 'swiper-example-mousewheel-control',
  title: 'Mousewheel control',
  components: {
    Swiper,
    SwiperSlide,
  },
  mounted() {

  },
  data() {
    return {
      isShowVideo: false,
      player: null,
      swiperOption: {
        direction: 'vertical',
        slidesPerView: 1,
        spaceBetween: 0,
        mousewheel: true,
        loop: true,
        speed: 800,

        pagination: {
          el: '.swiper-pagination',
          clickable: true
        }
      },
      bannerInfoList,
      appDownloadImg,

    }
  },
  methods: {
    openVideo() {
      this.initPlay();
      this.isShowVideo = true


    },
    closeVideo() {
      this.player.pause();
      this.isShowVideo = false;
    },
    initPlay() {
      this.player = new Player({
        id: 'mse',
        url: 'https://cdfinance-www.oss-cn-beijing.aliyuncs.com/xiangzhu-web/Video/qjfzsdwz.mp4',
        width: '80%',
        height: '70%',
        autoplay: true
      });
    }
  },

  mounted() {

  }

}
</script>

<style lang="less" scoped>
.swiper {
  height: calc(100vh - 215px);
}

.video-container {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.4);
  z-index: 99999;
  display: flex;
  justify-content: center;
  align-items: center;
  #mse {
  }
}

.close-icon {
  position: absolute;
  width: 20px;
  height: 20px;
  top: -15px;
  right: -20px;
}

.video-icon {
  width: 70px;
  height: 70px;
  position: fixed;
  z-index: 10000;
  bottom: 165px;
  right: 20px;
  cursor: pointer;
}
.falv-tag {
  width: 232px;
  height: 350px;
  position: fixed;
  z-index: 10000;
  top: 130px;
  left: 0;
  border-radius: 2px;
}
.content {
  margin: 0;
  padding: 0;
  display: flex;
  height: 100%;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  .left-content {
    height: 100%;
    display: flex;
    align-items: center;
    .swiper-img {
      height: 82%;
      margin-top: -5%;
    }
  }
  .right-content {
    margin-left: 90px;
    margin-top: -8px;
    .title {
      font-family: PingFang SC;
      font-style: normal;
      font-weight: 600;
      font-size: 48px;
      line-height: 67px;
      margin-bottom: 15px;
    }
    .desc {
      font-family: PingFang SC;
      width: 344px;
      font-size: 16px;
      line-height: 24px;
      color: rgba(51, 51, 51, 1);
    }
    .btn-box {
      margin-top: 20px;
      position: relative;
      .ios-icon,
      .andriod-icon {
        position: absolute;
        width: 18px;
        height: 22px;
        top: 8px;
        left: 20px;
      }
      .andriod-btn:hover .code-img,
      .ios-btn:hover .code-img {
        display: block;
      }
      .andriod-btn,
      .ios-btn {
        display: inline-block;
        width: 130px;
        height: 40px;
        background: #e8380d;
        border-radius: 4px;
        line-height: 40px;
        margin-right: 10px;
        color: #fff;
        font-size: 14px;
        position: relative;
        padding-left: 45px;
      }
    }
    .code-img {
      position: absolute;
      top: 40px;
      left: 0;
      width: 130px;
      height: 130px;
      margin-top: 4px;
      display: none;
    }
  }
}

// @media screen and (max-width: 1000px) {
//   img {
//     width: 240px;
//   }
// }
// @media screen and (min-width: 1000px) and (max-width: 1550px) {
//   img {
//     width: 280px;
//   }
// }
// @media screen and (min-width: 1550px) and (max-width: 1750px) {
//   img {
//     width: 280px;
//   }
// }
// @media screen and (min-width: 1750px) and (max-width: 1920px) {
//   img {
//     width: 340px;
//   }
// }
// @media screen and (min-width: 1920px) {
//   img {
//     width: 375px;
//   }
// }
/deep/ .wrapper .swiper-pagination-bullet-active {
  background: #cccccc;
}
</style>

<style>
.swiper-pagination-bullet {
  background: #cccccc;
  opacity: 0.8;
  height: 10px;
  width: 10px;
  transition: all 0.2s;
}

.swiper-pagination-bullet-active {
  width: 10px;
  height: 24px;
  border-radius: 12px;
  background-color: #e8380d;
}
</style>

