<template>
  <div class="footer-component">
    <div class="section">
      <div class="left-content">
        <div class="part1">
          <span
            class="title-link"
            v-for="(item, index) in list"
            :key="index"
            v-text="item.desc"
            @click.stop="goPage(item.route, item.type)"
          ></span>
        </div>
        <div class="part2">
          <p>
            <span>违法和不良信息举报电话:</span>
            <span>400-6678-003</span>
          </p>
          <p>
            <span>举报邮箱:</span>
            <span>service@chongho.net</span>
          </p>

          <p style="display: flex; align-items: center; margin-top: 6px">
            <img :src="imageM" alt />
            <a
              target="_blank"
              href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=11010102005838"
              >京公网安备 11010102005838号</a
            >
            <a target="_blank" href="https://beian.miit.gov.cn/"
              >京ICP备2021039295号</a
            >
          </p>
        </div>
      </div>
      <div class="right-content">
        <div class="code-img" v-for="(item, index) in codeImgList" :key="index">
          <img :src="item.imgSrc" alt />
          <span v-text="item.desc"></span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import codeImg1 from "@/assets/image/image_f.jpg";
import codeImg2 from "@/assets/image/image_e.jpg";
import imageM from "@/assets/image/image_m.png";
export default {
  data() {
    return {
      list: [
        // {
        //   desc: "意见反馈",
        //   route: "/comments",
        // },
        // {
        //   desc: "用户协议",
        //   route: "/rules",
        //   type: "user",
        // },
        // {
        //   desc: "隐私政策",
        //   route: "/rules",
        //   type: "privacy",
        // },
        // {
        //   desc: "合作机构",
        //   route: "/rules",
        //   type: "agency",
        // },
      ],

      codeImgList: [
        {
          desc: "微信小程序",
          imgSrc: codeImg1,
        },
        {
          desc: "微信公众号",
          imgSrc: codeImg2,
        },
      ],
      imageM,
    };
  },
  methods: {
    goPage(path, type) {
      if (this.$route.path != path) {
        this.$router.push({
          path,
        });
      }
      if (path == "/rules") {
        this.$nextTick(() => {
          setTimeout(() => {
            this.$bus.$emit("setCustomType", type);
          }, 300);
        });
      }
    },
  },
};
</script>

<style lang="less" scoped>
.footer-component {
  width: 100vw;
  height: 145px;
  .section {
    display: flex;
    justify-content: space-between;
    height: 145px;
    padding: 0 17.5%;

    background-color: #f6f6f6;
    .left-content {
      margin-top: 16px;
      .part1 {
        .title-link {
          font-size: 14px;
          line-height: 20px;
          margin-right: 36px;
          color: #333333;
          cursor: pointer;
        }
      }
      .part2 {
        margin-top: 15px;
        span,
        p {
          font-size: 12px;
          color: #666666;
          line-height: 18px;
        }
        img {
          height: 20px;
          width: 20px;
        }
        a {
          font-size: 12px;
          margin-left: 4px;
          color: rgb(102, 102, 102);
          text-decoration: none;
          height: 20px;
          line-height: 20px;
        }
        a:hover {
          text-decoration: underline;
        }
      }
    }
    .right-content {
      display: flex;
      margin-top: 24px;

      .code-img {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-left: 26px;
        img {
          width: 80px;
          height: 80px;
        }
        span {
          font-size: 12px;
          margin-top: 10px;
          color: #333333;
        }
      }
    }
  }
}
</style>
