<template>
    <div>
        <pdf ref="pdf" v-for="i in numPages" :key="i" :src="pdfLink" :page="i"></pdf>
    </div>
</template>
<script>
import pdf from 'vue-pdf'
export default {

    components: {
        pdf
    },
    data() {
        return {
            numPages: null, // pdf 总页数
            pdfLink: null
        }
    },

    methods: {
        getNumPages(url) {
            this.pdfLink = url;
            let loadingTask = pdf.createLoadingTask(url)
            loadingTask.promise.then(pdf => {
                this.numPages = pdf.numPages
            }).catch(err => {
                console.error('pdf 加载失败', err);
            })
        },
    }
}
</script>


